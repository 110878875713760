/* eslint-disable max-len */
import { Box, Text, VStack } from "@chakra-ui/react"
import React from 'react'

export default function OverviewStats({isOverViewStatsClicked}:{isOverViewStatsClicked:boolean}){
    return(
        <Box display={isOverViewStatsClicked?'block':'none'} height={{base:'273px',md:'380px',lg:'400px',xl:'480px'}} overflowY={'auto'} >
            <VStack >
                <Box >
                    <Text as="h2" textAlign="center" fontWeight={'400'} fontSize={'37.74px'}>4</Text>
                    <Text as="h2" textAlign="center" color="#757575" fontWeight={'300'} fontSize={'17.3px'}>Countries</Text>
                </Box>

                <Box>
                    <Text as="h2" textAlign="center" fontWeight={'400'} fontSize={'37.74px'}>83</Text>
                    <Text as="h2" textAlign="center" color="#757575" fontWeight={'300'} fontSize={'17.3px'}>Total sites</Text>
                </Box>

                <Box>
                    <Text as="h2" textAlign="center" fontWeight={'400'} fontSize={'37.74px'}>70</Text>
                    <Text as="h2" textAlign="center" color="#757575" fontWeight={'300'} fontSize={'17.3px'}>Stable status</Text>
                </Box>

                <Box >
                    <Text as="h2" textAlign="center" fontWeight={'400'} fontSize={'37.74px'}>13</Text>
                    <Text as="h2" textAlign="center" color="#757575" fontWeight={'300'} fontSize={'17.3px'}>Attention required</Text>
                </Box>

                <Box>
                    <Text as="h2" textAlign="center" fontWeight={'400'} fontSize={'37.74px'}>03</Text>
                    <Text as="h2" textAlign="center" color="#757575" fontWeight={'300'} fontSize={'17.3px'}>Task ongoing</Text>
                </Box>
            </VStack>
        </Box>
    )
}