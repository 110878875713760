/* eslint-disable @typescript-eslint/no-explicit-any */
type DebounceProp ={
    func: (...args: any[])=>void
}

const Debounce = ({func}:DebounceProp) => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    return function (...args:any[]) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            timer = null;
            func.apply( args);
        }, 500);
    };
};

export default Debounce;
