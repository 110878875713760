/* eslint-disable max-len */
import { Box, FormLabel, Img, Input, InputGroup, InputRightElement } from "@chakra-ui/react";

import React, { useState } from "react";

type inputFieldProps = {
    inputValue:string;
    label:string;
    isPasswordandConfirmPasswordEqual?:boolean;
    setError?:React.Dispatch<React.SetStateAction<string>>;
    setIsPasswordFocussed?: React.Dispatch<React.SetStateAction<boolean>>;
    setInputValue : React.Dispatch<React.SetStateAction<string>>
}

export default function PasswordInputField({inputValue,setError,isPasswordandConfirmPasswordEqual,setIsPasswordFocussed=()=>{},setInputValue,label}:inputFieldProps){
    const [isFocussed,setIsFocussed] = useState(false)
    const [type,setType] = useState(false)
    return(
        <Box mt={4}>
            <InputGroup width={{ sm: "412px" }}>
                <Input
                    placeholder=" "
                    type={type ? "text" : "password"}
                    value={inputValue}
                    border="1px"
                    onChange={(e) => setInputValue(e.target.value)}
                    variant="outline"
                    onFocus={() => {
                        setError?.('')
                        setIsFocussed(true);
                        setIsPasswordFocussed?.(true); // Optional chaining
                    }}
                    onBlur={() => {
                        setIsFocussed(false);
                        setIsPasswordFocussed?.(false); // Optional chaining
                    }}
                    _focus={{
                        borderWidth: "1px",
                        boxShadow: isPasswordandConfirmPasswordEqual?"0 0 0 2px rgba(76,187,23)":"0 0 0 3px rgba(0, 114, 219, 0.5)",
                    }}
                    _hover={{
                        borderColor: "#000000"
                    }}
                    height="40px"
                />

                <InputRightElement height="full" pointerEvents="auto">
                    <Img
                        onClick={(e) => {
                            e.stopPropagation(); // Prevents click from affecting Input
                            setType(!type);
                        }}
                        cursor="pointer"
                        src={type ? "/images/Eye.png" : "/images/closedEye.png"}
                        alt="show password"
                    />
                </InputRightElement>

                <FormLabel
                    position="absolute"
                    bg="white"
                    zIndex={1}
                    top="25%"
                    left="1rem"
                    transform={isFocussed || inputValue?.length > 0 ? "translateY(-90%) scale(0.85)" : "translateY(0)"}
                    transformOrigin="left top"
                    color={isFocussed || inputValue?.length > 0 ? "#15191E" : "#566676"}
                    fontSize="sm"
                    transition="0.2s ease all"
                    pointerEvents="none"
                >
                    {label}
                </FormLabel>
            </InputGroup>
           
        </Box>
    )
}