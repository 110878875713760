/* eslint-disable max-len */

import { Box,Button } from "@chakra-ui/react";
import Dropdown from "../../components/Dropdown";
import InputField from "../../components/inputField";
import PhoneInputField from "../../components/inputFieldWithCode";
import React, { useContext, useState } from "react";
import GlobalContext from "../../CommonMethods/GlobalContext";



export type salutations='Mr.' | 'Mrs.' | 'Ms.' | ''
export default function PersonalDetails(){
    const [inputValue1,setInputValue1] = useState('')
    const [inputValue2,setInputValue2] = useState('')
    const [mobileNumber,setMobileNumber] = useState('')
    const [salutations,setSalutations] = useState<salutations>('')
    const {setCurrentScreen} = useContext(GlobalContext)

    return(
        <Box>
            <Dropdown salutations={salutations} setSalutations={setSalutations}/>
            <InputField label="First Name" inputValue={inputValue1} setInputValue={setInputValue1}/>
            <InputField label="Last Name" inputValue={inputValue2} setInputValue={setInputValue2}/>
            <PhoneInputField label="Mobile number*" inputValue={mobileNumber} setInputValue={setMobileNumber}/>
            <Button
                mt={4}
                type={"button"}
                borderRadius="20px"
                width={{sm:"416px"}}
                height="40px"
                bg="#0072DB"
                disabled={inputValue1?.trim()===''||inputValue2?.trim()===''||mobileNumber?.trim()===''||salutations===''}
                _hover={{bg:'#0072DB'}}
                color="white"
                data-testid="continue"
                onClick={()=>setCurrentScreen('SignIn')}         
            >
                {"Continue"}
            </Button>
        </Box>
    )
}