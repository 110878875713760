/* eslint-disable max-len */
import { Box, Button, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import OtpInputField from "../../components/OtpInput";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../CommonMethods/GlobalContext";

export default function ActivateAccount(){
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [error, seterror] = useState(false);
    const navigate = useNavigate()
    const [timer, setTimer] = useState(30);
    const {setCurrentScreen} = useContext(GlobalContext)

    useEffect(() => {
        let intervalId:ReturnType<typeof setInterval> | undefined;

        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [timer]);

    const handleResendOtp = () =>{
        setTimer(30)
    }
    return(
        <VStack gap="24px" alignItems="center" justifyContent={'center'}>
            <Box mt="24px">
                <OtpInputField
                    inputs={otp}
                    setInputs={setOtp}
                    numInputs={6}
                    error={error}
                    seterror={seterror}
                />
                {timer===0?<Text mt={'20px'}
                    onClick={()=>{setOtp(Array(6).fill(""));handleResendOtp()}}
                    fontSize={'12px'} 
                    cursor="pointer"
                    fontWeight={'500'} 
                    color="#566676" 
                    textAlign={'center'}>Resend OTP</Text>
                    :<Text mt={'20px'} fontSize={'12px'} fontWeight={'500'} color="#566676" textAlign={'center'}>
                        {`Resend Code in`}<span style={{fontWeight:"bold",fontSize:'12px',color:"#566676"}}> {timer.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})} Seconds</span></Text> }
            </Box>
            <Box>
                <Button
                    mt={'24px'}
                    type={"button"}
                    borderRadius="20px"
                    width={{sm:"416px"}}
                    height="40px"
                    bg="#0072DB"
                    _hover={{bg:'#0072DB'}}
                    color="white"   
                    data-testid="activate_account"
                    disabled={otp?.includes("")}
                    onClick={()=>setCurrentScreen('ActivationSuccess')}
                >
                    {"Activate account"}
                </Button>
                <Button
                    mt={'24px'}
                    type={"button"}
                    borderRadius="20px"
                    width={{sm:"416px"}}
                    height="40px"
                    bg="none"
                    leftIcon={<img src="/images/leftArrow.png" alt="leftarrow"/>}
                    color="black"     
                    onClick={()=>navigate('/login')}      
                >
                    {"Back to login"}
                </Button>
            </Box>
        </VStack>
    )
}