/* eslint-disable max-len */
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Flex, FormLabel, Input, Stack, Text, VStack } from "@chakra-ui/react";
import {  useContext, useEffect, useState } from "react";
import UploadLogo from "./UploadLogos.tsx";
import { useForm,SubmitHandler } from "react-hook-form";
import InviteUsers from "./InviteUsers.tsx";
import Errors from "./Errors.tsx";
import { type SiteDetailType } from "../SiteManagement/SiteDummyData.ts";
import GlobalContext from "../../../CommonMethods/GlobalContext.tsx";
import React from "react";
import { useCreateSiteMutation } from "../../../graphql/generated.tsx";
import { useToast } from "@chakra-ui/react";

export type FormData = {
    "siteName": string;
    "country": string;
    "city": string;
    "displayCount":number;
    "owner":{"name":string,"email":string};
    "chainManager":{"name":string,"email":string};
    "asignee":{"name":string,"email":string};
    "status":string;
  };

  type CreateModalProps={
    isOpen:boolean;
    onClose:()=>void;
    refetch:()=>void
  }

export default function CreateModal({isOpen, onClose,refetch}:CreateModalProps){
    const toast = useToast()
    const [createProperty] = useCreateSiteMutation();
    const [showError,setShowError] = useState(false)
    const {setSiteDetails} = useContext(GlobalContext)
   
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const {register,handleSubmit,setValue,formState:{errors}} = useForm<FormData>()

    const handleSelectStatus = (status: string) => {
        setSelectedStatus(status);
        setValue('status', status);  // Set form value for React Hook Form
    };

    const resetSite =() =>{
        onClose()
        setSiteCreationPart(1)
    }

    const [siteCreationPart,setSiteCreationPart] = useState(1)

    const onSubmit:SubmitHandler<FormData> = async(data)=>{
        setSiteDetails((prev:SiteDetailType[])=>{
            const newSiteDetails:SiteDetailType={
                siteImage:'/images/image 10.png',
                name:data?.siteName,
                city: data?.city,
                country:data?.country,
                owner:data?.owner,
                chainManager:data?.chainManager,
                taskStatus:'',
                asignee:data?.asignee,
                displayCount:data?.displayCount,
                status:data?.status
            }
            return [newSiteDetails,...prev,]
        
        })
        try {
            await createProperty({
                variables: {
                    name: data?.siteName,
                    country: data?.country,
                    city: data?.city,
                    pincode: "123456",
                    status: data?.status,
                    displayCount: Number(data?.displayCount),
                    owner: data?.owner,
                    asignee: data?.asignee,
                    chainManager: data?.chainManager
                },
            });
            await refetch();
            toast({
                title: "Site Added",
                description: `The site ${data?.siteName} has been successfully created.`,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
            });
         
        } catch (e) {
            console.error('Error creating property:', e);
        }
        resetSite()
    }

    const handleScreens = () =>{
        if(siteCreationPart >= 3){
            setShowError(true)
        }
        if(errors.siteName?.message ||
            errors.city?.message ||
            errors.country?.message ||
            errors.displayCount?.message 
        ){
            setSiteCreationPart(1)
        }else if(errors.status?.message ||
            errors.asignee?.email?.message){
            setSiteCreationPart(3)
        }
        else if(siteCreationPart<=3){
            setSiteCreationPart(prev=>prev+1)
          
        }
            
    }

    useEffect(()=>{
        if(errors.siteName?.message ||
            errors.city?.message ||
            errors.country?.message ||
            errors.displayCount?.message 
        ){
            setSiteCreationPart(1)
        }else if(errors.status?.message ||
            errors.asignee?.email?.message){
            setSiteCreationPart(3)
        }
    },[errors])
  
    return(
        <Drawer
            isOpen={isOpen}
            placement='right'
            onClose={resetSite}
            size={'sm'}
            
            
            
        >
            <DrawerOverlay />
            
            <DrawerContent style={{top:'24px',marginRight:'36px'}}  >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DrawerCloseButton />
                    <DrawerHeader   borderBottomWidth='1px'>
                        Create new site
                    </DrawerHeader>

                    <DrawerBody>
                        <Stack spacing='24px'  >
                            <Flex  mt="6" mb="3" justifyContent={'space-between'}>
                                <Button  onClick={()=>setSiteCreationPart(1)} bg="#0072DB" width="44px" height="44px" borderRadius={'41px'} p={'10px'} alignItems={'center'} justifyContent={'center'}>
                                    <Text as="p" color="#FFFFFF" fontSize={'16px'} textAlign={'center'} fontWeight={'350'}>1</Text>
                                </Button>
                                <Divider width="130px" borderColor="gray.300" borderWidth="2px" my={4} />
                                <Button onClick={()=>setSiteCreationPart(2)} bg="#0072DB" width="44px" height="44px" borderRadius={'41px'} p={'10px'} alignItems={'center'} justifyContent={'center'}>
                                    <Text as="p" color="#FFFFFF" fontSize={'16px'} textAlign={'center'} fontWeight={'350'}>2</Text>
                                </Button>
                                <Divider width="130px" borderColor="gray.300" borderWidth="2px" my={4} />
                                <Button onClick={()=>setSiteCreationPart(3)} bg="#0072DB" width="44px" height="44px" borderRadius={'41px'} p={'10px'} alignItems={'center'} justifyContent={'center'}>
                                    <Text as="p" color="#FFFFFF" fontSize={'16px'} textAlign={'center'} fontWeight={'350'}>3</Text>
                                </Button>
                            </Flex>
                            <Box display={siteCreationPart===1?'block':'none'} >
                                <Box>
                                    <FormLabel mb="4" as="h2" color="#0072DB" htmlFor='username' fontWeight={'700'} fontSize={'20px'}>Site Details</FormLabel>
                                    <VStack gap={'6'}>
                                        <Input
                                            borderColor={'#7D7D7D'}
                                            {...register('siteName', { required: 'Name is required field' })}
                                            id='sitename'
                                            placeholder='Site name'
                                            onFocus={()=>setShowError(false)}
                                            autoFocus={false}
                                        />
                                        <Input
                                            borderColor={'#7D7D7D'}
                                            {...register('country', { required: 'Country is required field' })}
                                            id='country'
                                            placeholder='Country'
                                            onFocus={()=>setShowError(false)}
                                            autoFocus={false}
                                        />
                                        <Input
                                            borderColor={'#7D7D7D'}
                                            {...register('city', { required: 'City is required field' })}
                                            id='city'
                                            placeholder='City'
                                            onFocus={()=>setShowError(false)}
                                            autoFocus={false}
                                        />
                                        <Input
                                            type="number"
                                            borderColor={'#7D7D7D'}
                                            {...register('displayCount', {
                                                required: 'Display Count is a required field',
                                                pattern: {
                                                    value: /^\d+$/, // Accepts only digits
                                                    message: 'Display Count must be a number',
                                                },
                                            })}
                                            id='pin'
                                            placeholder='Display Count'
                                            onFocus={()=>setShowError(false)}
                                            autoFocus={false}
                                        />
                                    </VStack>
                                </Box>
                        
                                {/* <Box mt="6">
                                    <Text mt="6">Select Location</Text>
                                    <Img src={'/images/image 31.png'}/>
                                </Box> */}
                            </Box>
                            <Box display={siteCreationPart===2?'block':'none'} >
                                <UploadLogo/>
                            </Box>

                            <Box display={siteCreationPart>=3?'block':'none'}> 
                                <InviteUsers setShowError={setShowError} register={register} selectedStatus={selectedStatus} handleSelectStatus={handleSelectStatus}/>
                            </Box>
                            {/* <Box mt={"30"} gap={6} alignItems="center" justifyContent="center" display={siteCreationPart>3?'block':'none'}>
                                <Image src={'/images/7efs 1.png'}/>
                                <Text textAlign="center" fontWeight={'600'} fontSize={'24px'}>Site created successfully!</Text>
                            </Box> */}
                        </Stack>
                    </DrawerBody>

                    <DrawerFooter
                        position="absolute"
                        bottom="50px"
                        width="100%"
                        borderTopWidth="1px"
                        alignItems="center"
                        justifyContent="center"
                        padding="1rem"
                    >
                        {showError&&<Errors errors={errors} />}
                        <Button
                            type={siteCreationPart === 4 ? "submit" : "button"}
                            value="submit"
                            onClick={handleScreens}
                            borderRadius="20px"
                            width="250px"
                            height="40px"
                            rightIcon={<ArrowForwardIcon />}
                            colorScheme="blue"
                        >
                            {siteCreationPart >= 3 ? "Finish Site Creation" : "Next"}
                        </Button>
                    </DrawerFooter>
                </form>
            </DrawerContent>
            
        </Drawer>
    )
    
}