/* eslint-disable max-len */
import {  Button, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function WelcomePage(){
    const navigate = useNavigate()
    return(
        <VStack gap="48px" mt="20px" mb="112px">
            <Button bg="#00438A0F" width={{sm:'144px'}} borderRadius="20px" color="black">
                <Text fontWeight={'700'} fontSize={'16px'}>Signage</Text>
            </Button>
            <Button  width={{sm:'144px'}} onClick={()=>navigate('/login')} bg="#00438A0F"  borderRadius="20px" color="black">
                <Text data-testid="protv"fontWeight={'700'} fontSize={'16px'}>ProTV</Text>
            </Button>
        </VStack>
    )
}