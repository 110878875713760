/* eslint-disable max-len */
import {   Box, FormLabel, Image, Input, Text, VStack } from "@chakra-ui/react"
import  { ChangeEvent, useState } from "react"
import React from 'react'


export default function UploadLogo(){
    const[image1,setImage1] = useState('')
    const[image2,setImage2] = useState('')
    async function handleUploadChange(e:ChangeEvent<HTMLInputElement>) {
       
        const selectedFile1 = e.target.files?.[0];
        if(selectedFile1){
            setImage1(URL.createObjectURL(selectedFile1));
        }

    }
    async function handleUploadChange2(e:ChangeEvent<HTMLInputElement>) {
       
        const selectedFile2 = e.target.files?.[0];
        if(selectedFile2){
            setImage2(URL.createObjectURL(selectedFile2))
        }

    }
    
    

    return(
        <>
            <FormLabel mb="4" as="h2" color="#0072DB" htmlFor='username' fontWeight={'700'} fontSize={'20px'}>Add brand details</FormLabel>
            <VStack gap={'4'}>
                <Box justifyContent={'left'} >
                    <Text>Add Logo File</Text>
                    <label htmlFor='file-upload'>
                        <Image cursor={'pointer'} src={image1===""?'/images/ri_image-add-line.png':image1} />
                    </label>
                    <Input
                        type="file"
                        id="file-upload"
                        display={'none'}
                        accept="image/jpeg,image/png"
                        onChange={(e)=>handleUploadChange(e)}
                    />
                </Box>
                <Box justifyContent={'left'} >
                    <Text>Add Site Image</Text>
                    <label htmlFor='file-upload2'>
                        <Image cursor={'pointer'} src={image2===""?'/images/ri_image-add-line.png':image2} />
                    </label>
                    <Input
                        type="file"
                        id="file-upload2"
                        display={'none'}
                        accept="image/jpeg,image/png"
                        onChange={(e)=>handleUploadChange2(e)}
                    />
                </Box>
            </VStack>

        </>
    )
}