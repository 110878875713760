/* eslint-disable max-len */
import { Box, Flex, Img, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack} from "@chakra-ui/react"
import  { useContext, useState } from "react"
import TableMainBar from "../../../components/TableMainBar.tsx"
import TableFooter from "../../../components/TableFooter.tsx"
import OverviewStats from "./OverviewStats.tsx"
import React from 'react'
import { SortDirection, useGetPropertiesQuery } from "../../../graphql/generated.tsx"
import GlobalContext from "../../../CommonMethods/GlobalContext.tsx"
import SearchEmptyScreen from "../../../components/SearchEmptyScreen.tsx"
// import { Spinner } from "@chakra-ui/react"



export default function SiteListing () {
    const [page, setPage] = useState(1);
    const {skip,screenLoading} = useContext(GlobalContext)
    const {searchTerm} = useContext(GlobalContext)
    const [cursorHistory, setCursorHistory] = useState<(string | null)[]>([null]); // Initial cursor is null
    const [cursor, setCursor] = useState<string | null>(null);
    const { data, loading,refetch } = useGetPropertiesQuery( {
        variables: {
            cursor: cursor,
            limit: 10,
            sort: "createdAt",
            sortDirection: SortDirection.Desc,
            search: searchTerm,
        },
        skip:skip,
        onCompleted: (fetchedData) => {
            const nextCursor = fetchedData.propertyWithTVs?.nextCursor;
            if (nextCursor && !cursorHistory.includes(nextCursor)) {
                // Append the nextCursor to cursor history only if it's new
                setCursorHistory((prev) => [...prev, nextCursor]);
            }
        },
    });

    // Next Button Handler
    const handleNext = () => {
        if (data?.propertyWithTVs?.nextCursor) {
            setPage((prev) => prev + 1);
            setCursor(data.propertyWithTVs.nextCursor);
        }
    };
  
    // Previous Button Handler
    const handlePrev = () => {
        if (page > 1) {
            setPage((prev) => prev - 1);
            const prevCursor = cursorHistory[page - 2]; // Get the cursor of the previous page
            setCursor(prevCursor);
        }
    };

    


    const [isOverViewStatsClicked,setIsOverViewStatsClicked] = useState(false)
    return(
        <Box>
            {loading||screenLoading?<Box  bg={'white'} height={'calc(100vh - 262px)'} marginTop={'40px'} marginLeft={'40px'} marginRight={'40px'}><Spinner size="xl" marginTop={'270px'} marginLeft="800px"/></Box>:data?.propertyWithTVs?.totalCount===0?<SearchEmptyScreen text="No exact matches found. Please try a different search"/>:  <Box mx={10} mt={10} mb="0" pb="0" bg={'white'}  height={{base:'calc(100vh - 262px)',lg:'calc(100vh - 221px)'}}  px="10" pt="10" borderTopRadius={15}>
                <TableMainBar refetch={refetch} setIsOverViewStatsClicked={setIsOverViewStatsClicked}/>
                <Flex>
                    <TableContainer width={"100%"} sx={{
                        '&::-webkit-scrollbar': {
                            width: '9px',
                            borderRadius: '8px',
                            backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        },
                   
                        '&::-webkit-scrollbar-thumb': {
                    
                            backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        },
                    }} h={['calc(100vh - 400px)','calc(100vh - 400px)','calc(100vh - 400px)','calc(100vh - 350px)','calc(100vh - 330px)']} overflowX={'auto'} overflowY={'auto'}>
                        <Table width={"full"} layout={'auto'} >
                            <Thead>
                                <Tr width={'full'} fontWeight={'600'} fontSize={'14px'}>
                                    <Th px={'18px'} minWidth={'190px'} py={2}>Site</Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Country</Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Owner </Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Chain manager </Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Task status </Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Asignee </Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Displays </Th>
                                    <Th px={'18px'} minWidth={'140px'} py={2}>Overview status </Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {data?.propertyWithTVs?.properties?.map((item,index)=>(
                                    <Tr key={index}>
                                        <Td pl={'2px'} py={5}>
                                            <Flex gap={'4'}>
                                                <Img src={'/images/image 11 (2).png'}/>
                                                <VStack alignItems={'left'} >
                                                    <Text as="h3" fontSize={'16px'} fontWeight={'600'}>{item?.name}</Text>
                                                    <Text as="h3" fontSize={'12px'} color="#343434" fontWeight={'400'}>{item?.city}</Text>
                                                </VStack>
                                            </Flex>
                                        </Td>
                                        <Td px={'18px'} py={2} fontSize={'14px'} fontWeight={'400'}>{item?.country}</Td>
                                        <Td px={'18px'} py={2}><VStack alignItems={'left'}>
                                            <Text fontSize={'14px'} fontWeight={'400'}>{item?.owner?.name===undefined?'NA':item?.owner?.name}</Text>
                                            <Text as="h3" fontSize={'12px'} color="#343434" fontWeight={'400'}>{item?.owner?.email}</Text>
                                        </VStack>
                                        </Td>
                                        <Td px={'18px'} py={2}><VStack alignItems={'left'}>
                                            <Text fontSize={'14px'} fontWeight={'400'}>{item?.chainManager?.name===undefined?'NA':item?.chainManager?.name}</Text>
                                            <Text as="h3" fontSize={'12px'} color="#343434" fontWeight={'400'}>{item?.chainManager?.email}</Text>
                                        </VStack>
                                        </Td>
                                        <Td px={'18px'} fontSize={'14px'} fontWeight={'400'} py={2}>{'Claiming Ongoing'}</Td>
                                        <Td px={'18px'} py={2}><VStack alignItems={'left'}>
                                            <Text fontSize={'14px'} fontWeight={'400'}>{item?.asignee?.name===undefined?'NA':item?.asignee?.name}</Text>
                                            <Text as="h3" fontSize={'12px'} color="#343434" fontWeight={'400'}>{item?.asignee?.email!==''&&item?.asignee?.email}</Text>
                                        </VStack>
                                        </Td>
                                        <Td px={'18px'}  py={2} >
                                            <VStack alignItems={'left'}>
                                                <Text as="h3" fontSize={'24px'} fontWeight={'400'}>{item?.displayCount}</Text>
                                                <Text as="h3" fontSize={'11px'} fontWeight={'300'} color="#343434">Claimed</Text>
                                            </VStack>
                                        </Td>
                                        <Td  w={20} > 
                                            <Text as='h3' textAlign={'center'} color={'#FFFFFF'} px={'18px'} borderRadius={5} bg={item?.status==='Stable'?"#41B546":'#F2A40E'} padding={3} fontWeight='400' fontSize='14px' py={2}>{item?.status}</Text>
                                        </Td>
                                    </Tr>
                                ))

                                }
                        
                            </Tbody>              
                        </Table>
                
                    </TableContainer> 
                    {<OverviewStats isOverViewStatsClicked={isOverViewStatsClicked}/>}
                </Flex>
            </Box>}
            {!loading&&!screenLoading&&!(data?.propertyWithTVs?.totalCount===0)&&<TableFooter handlePrev={handlePrev} handleNext={handleNext} data={data} page={page}/>}
        </Box>

    )
}