/* eslint-disable max-len */
import { Box, Button, Checkbox, HStack, Img, Text, VStack } from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import InputField from "../../components/inputField";
import { passwordCriteria } from "./components/passwordCriteria";
import PasswordInputField from "../../components/PasswordInputField";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GlobalContext from "../../CommonMethods/GlobalContext";
import PersonalDetails from "./PersonalDetails";

export default function SignIn() {
    const navigate = useNavigate();
    const { currentScreen, setCurrentScreen, email, setEmail } = useContext(GlobalContext);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [isPasswordFocussed, setIsPasswordFocussed] = useState(false);
    const [error, setError] = useState(""); // State for error message

    // Email validation function
    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // checks for email pattern
    };

    // Password validation function based on criteria
    const isPasswordValid = (password: string) => {
        return Object.keys(passwordCriteria).every(criteria =>
            passwordCriteria[criteria]["regex"].test(password)
        );
    };

    // Handle create account button click
    const handleCreateAccount = () => {
        if (!isValidEmail(email)) {
            setError("Please enter a valid email address.");
        } else if (password !== confirmPassword) {
            setError("Passwords do not match.");
        } else {
            setError("");
            setCurrentScreen('OTP');
            navigate('/');
        }
    };

    // Handle checkbox change event
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    // Check if the password meets the requirements
    const isPasswordMeetsCriteria = password?.length > 0 && isPasswordValid(password);

    return (
        <Box bg="#0072DB" height="100vh" py="8">
            <Box
                borderRadius="10px"
                bg="white"
                top="125px"
                mx="auto"
                left="660px"
                height={{ lg: "800px" }}
                width={{ sm: "550px", md: "600px" }}
                px="94px"
                pt={password?.length > 0 ? '30px' : "40px"}
                pb="30px"
            >
                <VStack gap="40px">
                    <Img src="/images/TPV_Logo.png" alt="TPVision logo" />
                    <VStack gap="8px">
                        <Text color="#15191E" fontSize="24px" fontWeight="700" as="h3">Create an account</Text>
                        <Text color="#15191E" fontSize="16px" fontWeight="350">
                            Already have an account?{" "}
                            <Link to="/login" style={{ textDecoration: "underline", color: "#0072DB" }}>Log in</Link>
                        </Text>
                    </VStack>
                    {currentScreen !== 'SignIn' && <PersonalDetails />}
                    {currentScreen === 'SignIn' && (
                        <Box>
                            <InputField setError={setError} label="Email" inputValue={email} setInputValue={setEmail} />
                            <PasswordInputField
                                label="Password"
                                setError={setError}
                                inputValue={password}
                                setInputValue={setPassword}
                                setIsPasswordFocussed={setIsPasswordFocussed}
                            />
                            {password?.length > 0 && isPasswordFocussed && (
                                <Box mt="4px">
                                    <Text fontWeight="350" fontSize="12px" color="#566676">Password must contain:</Text>
                                    <ul style={{ listStyleType: 'none', paddingLeft: "8px" }}>
                                        {Object.keys(passwordCriteria).map((criteria, index) => (
                                            <li className="mb-1.5 flex" key={criteria}>
                                                <HStack>
                                                    <span>
                                                        <img
                                                            src={(password === '') ? `/images/password_not_met.png` : (!passwordCriteria[criteria]["regex"].test(password) && password !== '') ? '/images/password_not_met.png' : "/images/criteria_met.png"}
                                                            alt="icon"
                                                        />
                                                    </span>
                                                    <Text fontWeight="350" fontSize="12px" color="#566676">
                                                        {index === 4 ? <>{criteria.split('\n')[0]}<br />{criteria.split('\n')[1]}</> : criteria}
                                                    </Text>
                                                </HStack>
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                            <PasswordInputField
                                label="Confirm Password"
                                setError={setError}
                                isPasswordandConfirmPasswordEqual={password?.trim()===confirmPassword?.trim()&&isPasswordMeetsCriteria}
                                inputValue={confirmPassword}
                                setInputValue={setConfirmPassword}
                            />

                            {/* Error message */}
                            {error && (
                                <Text color="red.500" textAlign="center" mt="4" fontSize="14px">
                                    {error}
                                </Text>
                            )}

                            <Checkbox
                                isChecked={isChecked}
                                onChange={handleCheckboxChange}
                                mt="4"
                                colorScheme="blue"
                                size="lg"
                            >
                                <HStack fontSize="16px" fontWeight="350">
                                    <Text>I accept the</Text>
                                    <Text color="#0072DB">Terms of Service</Text>{" "}
                                    <Text>and</Text>
                                    <Text color="#0072DB">Privacy Policy</Text>
                                </HStack>
                            </Checkbox>
                            <Button
                                mt="24px"
                                type="button"
                                borderRadius="20px"
                                width={{ sm: "416px" }}
                                height="40px"
                                bg="#0072DB"
                                _hover={{ bg: '#0072DB' }}
                                color="white"
                                data-testid="create_account"
                                disabled={
                                    email.trim() === "" ||
                                    password.trim() === "" ||
                                    confirmPassword.trim() === "" ||
                                    !isChecked ||
                                    !isPasswordMeetsCriteria // Disable button if password doesn't meet criteria
                                }
                                onClick={handleCreateAccount}
                            >
                                {"Create account"}
                            </Button>
                            <Button
                                mt="24px"
                                type="button"
                                borderRadius="20px"
                                width={{ sm: "416px" }}
                                height="40px"
                                bg="none"
                                onClick={() => { navigate('/login'); setCurrentScreen('PersonalDetails') }}
                                leftIcon={<img src="/images/leftArrow.png" alt="leftarrow" />}
                                color="black"
                            >
                                {"Back to login"}
                            </Button>
                        </Box>
                    )}
                </VStack>
                <Img mt={password?.length > 0 ? '4px' : "40px"} src="/images/Copyrights.png" alt="copyright" />
            </Box>
        </Box>
    );
}
