/* eslint-disable no-useless-escape */
export type PasswordCriterion = {
    regex: RegExp;
  };

export const passwordCriteria: Record<string, PasswordCriterion> = {
    "8 - 32 characters": {
        regex: /^.{8,32}$/,
    },
    "Upper & lower case":{
        regex: /^(?=.*[a-z])(?=.*[A-Z])/,
    },
    "A special character: #$%^&*() ": {
        regex: /[!@#$%^&*()\-_+=\[\]{}|\\\/:;"'<>,.?~`¬¢£€¥§©®™°µ×÷]/,
    },
    "A number": {
        regex: /\d/,
    },
   
};


