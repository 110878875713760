/* eslint-disable max-len */
import React,{ Context, createContext,ReactNode, useState } from "react";
import {SiteDetails, type SiteDetailType } from "../pages/Sites/SiteManagement/SiteDummyData";
import { type currentScreen } from "../pages/Authentication";
const breakPoints = {'sm':'640','md':'790','lg':'1024','xl':'1280','2xl':'1560'}

type ScreenTypes = 'sm'|'md'|'lg'|'xl'|'2xl'

// Define the type for the context value
interface GlobalContextType {
    email:string;
    setEmail:React.Dispatch<React.SetStateAction<string>>;
    bp: (screenSize: ScreenTypes) => string; // bp function type
    siteDetails:SiteDetailType[];
    setSiteDetails:React.Dispatch<React.SetStateAction<SiteDetailType[]>>; // Type for setSiteDetails
    searchTerm:string,
    setSearchTerm:React.Dispatch<React.SetStateAction<string>>,
    skip:boolean,
    setSkip:React.Dispatch<React.SetStateAction<boolean>>
    screenLoading:boolean,
    setScreenLoading:React.Dispatch<React.SetStateAction<boolean>>,
    currentScreen:currentScreen,
    setCurrentScreen:React.Dispatch<React.SetStateAction<currentScreen>>
    
}

const GlobalContext: Context<GlobalContextType > = createContext<GlobalContextType >({
    bp: (screenSize) => `@media (min-width:${breakPoints[screenSize]}px)`,
    siteDetails: SiteDetails, // Empty array as default
    setSiteDetails: () => {}, // No-op function as a placeholder
    searchTerm:'',
    setSearchTerm:()=>{},
    skip:false,
    setSkip:()=>{},
    screenLoading:false,
    setScreenLoading:()=>{},
    currentScreen:'welcomeScreen',
    setCurrentScreen:()=>{},
    email:'',
    setEmail:()=>{}
});

interface GlobalProviderProps {
    children: ReactNode; // Accepts React nodes as children
}

const GlobalProvider = ({children}:GlobalProviderProps) => {
    const [siteDetails,setSiteDetails] = useState<SiteDetailType[]>(SiteDetails)
    const [searchTerm, setSearchTerm] = useState("");
    const [skip,setSkip] = useState(false)
    const [screenLoading,setScreenLoading] = useState(false)
    const [email,setEmail] = useState('')
    const [currentScreen,setCurrentScreen] = useState<currentScreen>('welcomeScreen')
    /**
      * The function `bp` returns a media query string based on the input `screenSize`.
      * @returns The function `bp` is returning a string that represents a CSS media query with a
      * minimum width based on the `screenSize` parameter. The `${breakPoints?.screenSize}` is likely
      * intended to access a variable or object named `breakPoints` to get the specific screen size
      * value
      */
    const bp = (screenSize:ScreenTypes) =>{
        return `@media (min-width:${breakPoints[screenSize]}px`
    }
    

    return(
        <GlobalContext.Provider value={{email,setEmail,bp,currentScreen,setCurrentScreen,siteDetails,setSiteDetails,searchTerm,setSearchTerm,skip,setSkip,screenLoading,setScreenLoading}}>
            {children}
        </GlobalContext.Provider>
    )

}

export {GlobalProvider}
export default GlobalContext