import {Route, Routes, useLocation} from 'react-router-dom'
import RootLayout from '../components/RootLayout'
import React from 'react'
import Registration from '../pages/Authentication'
import Login from '../pages/Authentication/Login'
import SignIn from '../pages/Authentication/SignIn'
import { useVarSelector } from '../redux/hooks'

export default function NavigationRoutes(){
    const isLoggedIn  = useVarSelector(state=>state.persistedState.isLoggedIn)
    const location = useLocation()

    return(
        <> 
            {<Routes location={location} key={location.pathname}>
                {!isLoggedIn?<>
                    <Route path="/" element={<Registration/>}/>
                    <Route path='/login' element={<Login/>}/>
                    <Route path='/signup' element={<SignIn/>}/>
                </>:<>
                    <Route path='/' element={<RootLayout/>}/>
                </>
                }
             

            </Routes>}
        </>
    ) 
}