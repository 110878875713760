import React from "react";
import { IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { LinkItems } from "./Sidebar";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
export default function MobileSideNav ({onToggle,isOpen}:{onToggle:()=>void,isOpen:boolean}) {
    return(
        <Menu>
            <MenuButton display={{base:'block',md:'none'}}>
               
                <IconButton
                    onClick={onToggle}
                    icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
                    variant={'ghost'}
                    aria-label={'Toggle Navigation'}
                />
            
            </MenuButton>

            <MenuList>
                {
                    LinkItems?.map((item,index)=>{return(
                        <MenuItem key={index} gap={8} px={10} mb={7}>
                            <Image
                                src={item?.icon}
                            />
                            <Text fontSize={'15px'} fontWeight={400} >{item.name}</ Text>
                        </MenuItem>
                    )})
                }
                
            </MenuList>
        </Menu>
    )
}