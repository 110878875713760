/* eslint-disable max-len */
import { Box,  Img, Spinner, Text, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WelcomeScreen from "../../components/WelcomeScreen";

export default function LoggingInScreen() {
    const [showWelcomeScreen,setShowWelcomeScreen] = useState(false)

    useEffect(() => {
        // Set timeout to show the animation screen after 2 seconds
        const timer = setTimeout(() => {
            setShowWelcomeScreen(true);
        }, 2000);
    
        // Clean up timeout when the component is unmounted or when showLogin changes
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {!showWelcomeScreen?<Box bg="#0072DB" height='100vh' py="8" >
                <Box borderRadius="10px" bg="white" top={"125px"} mx="auto" left="660px" height={{lg:"800px"}} width={{sm:"550px",md:"600px"}} px="94px" pt={"80px"} pb='30px'>
                    <VStack gap={"50px"}>  
                        <Img src={'/images/TPV_Logo.png'}  alt="TPVision logo"/>
                        <VStack gap={'8px'}>
                            <Text as="h3" color="#15191E"  fontSize="24px" fontWeight="700" >Logging in</Text>
                            <Text as="h3" color="#15191E"  fontSize="16px" fontWeight="350">New user? <Link to="/signup" style={{textDecoration:"underline",color:'#0072DB'}}>Create account</Link></Text>
                        </VStack>
                        <Spinner  mt="110px" mb="130px" size="xl" color={'#0072DB'}/>
                  
                    </VStack>
                    <Img mt={"40px"} src={'/images/Copyrights.png'} alt="copyright"/>
                </Box>
            </Box>:<Box bg="#0072DB" height='100vh' py="8" >
                {<WelcomeScreen/>}
            </Box>}
        </>
     
    );
}
