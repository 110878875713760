import { Box } from "@chakra-ui/react";
import LottieAnimation from "./LottieAnimation";
import React from "react";
import welcomeScreen from '../helpers/welcomeScreen.json'

export default function WelcomeScreen(){
    return(
        <Box overflow="hidden" height="calc(100% - 130px)" position="relative">
            <Box position="relative" top="-100px" pb="130px">
                <LottieAnimation  animateFile={welcomeScreen} />
            </Box>
        </Box>
    )
}