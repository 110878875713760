// src/ApolloProvider.tsx (or .js)
import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import { GlobalProvider } from '../CommonMethods/GlobalContext';

const client = new ApolloClient({
    link: new HttpLink({
        uri: 'https://api.wave-htv-dev.tpvai.com/graphql', // Replace with your API URL
    }),
    cache: new InMemoryCache(),
});

const AppApolloProvider = ({ children }: { children: React.ReactNode }) => (
    <ChakraProvider>
        <GlobalProvider>
            <ApolloProvider client={client}>
                {children}
            </ApolloProvider>
        </GlobalProvider>
    </ChakraProvider>
  
);

export default AppApolloProvider;