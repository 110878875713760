/* eslint-disable max-len */
import { Box, Flex, HStack, Img, Spacer, Text } from "@chakra-ui/react"
import { Button } from "antd";
import React from "react"
import { GetPropertiesQuery } from "../graphql/generated";

type TableFooterType = {
    handlePrev : ()=>void;
    handleNext : ()=>void;
    page:number;
    data:GetPropertiesQuery | undefined
}
export default function TableFooter({handlePrev,handleNext,page,data}:TableFooterType){
    return(
        <Box  bg={'white'} mx={10} px="10" pt='3' pb="5" borderBottomRadius={15} position="sticky"  zIndex="999">
            <Flex>
                <HStack>
                    <Text color="#566676" as="h2" fontWeight={'350'} fontSize={'12px'}>Entries per page</Text>
                    <Text as="h2" fontWeight={'700'} fontSize={'16px'}>50</Text>
                    <Img src={'/images/ChevronDown.png'}/>
                </HStack>
                <Spacer/>
                <HStack>
                    <Text as="h2" color="#566676" fontWeight={'350'} fontSize={'12px'}>Showing 01-50 of 60</Text>
                    <Button onClick={handlePrev} disabled={page === 1}><Img src={'/images/ChevronLeft.png'}/></Button>
                    <Button onClick={handleNext}  disabled={!data?.propertyWithTVs?.nextCursor}><Img src={'/images/path2.png'}/></Button>
                </HStack>

            </Flex>
        </Box>
    )
}