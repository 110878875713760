/* eslint-disable max-len */
import { ChevronDownIcon } from "@chakra-ui/icons"
import { Box, Button, ButtonGroup, Flex, Heading, Img, Menu, MenuButton, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react"

import React from "react"
import CreateModal from "../pages/Sites/SiteCreation/CreateModal.tsx"


export default function TableMainBar({setIsOverViewStatsClicked,refetch}:{
    setIsOverViewStatsClicked: React.Dispatch<React.SetStateAction<boolean>>,refetch:()=>void
  }){
    const {isOpen,onOpen,onClose} = useDisclosure()
    return(
        <Flex gap={2} flexWrap={'wrap'} mb='40px' alignItems='center' justifyContent={'space-between'}>
            <Box>
                <Heading as={'h1'} fontSize={'24px'}  fontWeight={'600'}>All Sites</Heading>
            </Box>

            <Menu >
                <MenuButton variant='ghost' display={{base:'block',lg:'none'}} as={Button} rightIcon={<ChevronDownIcon />}>
                    Actions
                </MenuButton>
                <MenuList gap={5} mb={5}>
                    <MenuItem ><Button  variant='ghost' _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/octicon_filter-16.png'}/>}>Filter</Button></MenuItem>
                    <MenuItem ><Button variant='ghost' _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/mi_filter.png'}/>}>Customise</Button></MenuItem>
                    <MenuItem><Button variant='ghost' _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/pepicons-pencil_map.png'}/>}>Map view</Button></MenuItem>
                    <MenuItem><Button variant='ghost' onClick={()=>{setIsOverViewStatsClicked(prev=>!prev)}} _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/hugeicons_market-analysis.png'}/>}>Overview stats</Button></MenuItem>
                </MenuList>
            </Menu>
            <ButtonGroup display={{base:'none',lg:'block'}}  color='black' variant='ghost' bg='transparent' spacing={['6','4']}>
                <Button _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/octicon_filter-16.png'}/>}>Filter</Button>
                <Button _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/mi_filter.png'}/>}>Customise</Button>
                <Button _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  rightIcon={<Img src={'/images/pepicons-pencil_map.png'}/>}>Map view</Button>
                <Button _hover={{bg:'blue.100'}} fontSize={'14px'} fontWeight={'600'}  onClick={()=>{setIsOverViewStatsClicked(prev=>!prev)}} rightIcon={<Img src={'/images/hugeicons_market-analysis.png'} />}>Overview stats</Button>
            </ButtonGroup>
          
        

            <Box  >
                <Button width={{base:'min--content',md:"224px"}} _hover={{ 
                    bg: 'blue.600',           // Background color on hover           // Text color on hover
                }} padding={5}  onClick={onOpen} borderRadius={20} bg="#0072DB" leftIcon={<Img src={'/images/path.png'}/>}><Heading as="h2" fontSize={'16px'} color="white" fontWeight={'700'}>Create new site</Heading></Button>
            </Box>   
            <CreateModal isOpen={isOpen} refetch={refetch} onClose={onClose}/>
        </Flex>
    )
}