export const phoneCodeArray:string[] = [
    "297 - (ABW)",
    "93 - (AFG)",
    "244 - (AGO)",
    "1 - (AIA)",
    "358 - (ALA)",
    "355 - (ALB)",
    "376 - (AND)",
    "971 - (ARE)",
    "54 - (ARG)",
    "374 - (ARM)",
    "1 - (ASM)",
    "672 - (ATA)",
    "262 - (ATF)",
    "1 - (ATG)",
    "61 - (AUS)",
    "43 - (AUT)",
    "994 - (AZE)",
    "257 - (BDI)",
    "32 - (BEL)",
    "229 - (BEN)",
    "599 - (BES)",
    "226 - (BFA)",
    "880 - (BGD)",
    "359 - (BGR)",
    "973 - (BHR)",
    "1 - (BHS)",
    "387 - (BIH)",
    "590 - (BLM)",
    "375 - (BLR)",
    "501 - (BLZ)",
    "1 - (BMU)",
    "591 - (BOL)",
    "55 - (BRA)",
    "1 - (BRB)",
    "673 - (BRN)",
    "975 - (BTN)",
    "47 - (BVT)",
    "267 - (BWA)",
    "236 - (CAF)",
    "1 - (CAN)",
    "61 - (CCK)",
    "41 - (CHE)",
    "56 - (CHL)",
    "86 - (CHN)",
    "225 - (CIV)",
    "237 - (CMR)",
    "243 - (COD)",
    "242 - (COG)",
    "682 - (COK)",
    "57 - (COL)",
    "269 - (COM)",
    "238 - (CPV)",
    "506 - (CRI)",
    "53 - (CUB)",
    "599 - (CUW)",
    "61 - (CXR)",
    "1 - (CYM)",
    "357 - (CYP)",
    "420 - (CZE)",
    "49 - (DEU)",
    "253 - (DJI)",
    "1 - (DMA)",
    "45 - (DNK)",
    "1 - (DOM)",
    "213 - (DZA)",
    "593 - (ECU)",
    "20 - (EGY)",
    "291 - (ERI)",
    "212 - (ESH)",
    "34 - (ESP)",
    "372 - (EST)",
    "251 - (ETH)",
    "358 - (FIN)",
    "679 - (FJI)",
    "500 - (FLK)",
    "33 - (FRA)",
    "298 - (FRO)",
    "691 - (FSM)",
    "241 - (GAB)",
    "44 - (GBR)",
    "995 - (GEO)",
    "44 - (GGY)",
    "233 - (GHA)",
    "350 - (GIB)",
    "224 - (GIN)",
    "590 - (GLP)",
    "220 - (GMB)",
    "245 - (GNB)",
    "240 - (GNQ)",
    "30 - (GRC)",
    "1 - (GRD)",
    "299 - (GRL)",
    "502 - (GTM)",
    "594 - (GUF)",
    "1 - (GUM)",
    "592 - (GUY)",
    "852 - (HKG)",
    "672 - (HMD)",
    "504 - (HND)",
    "385 - (HRV)",
    "509 - (HTI)",
    "36 - (HUN)",
    "62 - (IDN)",
    "44 - (IMN)",
    "91 - (IND)",
    "246 - (IOT)",
    "353 - (IRL)",
    "98 - (IRN)",
    "964 - (IRQ)",
    "354 - (ISL)",
    "972 - (ISR)",
    "39 - (ITA)",
    "1 - (JAM)",
    "44 - (JEY)",
    "962 - (JOR)",
    "81 - (JPN)",
    "76 - (KAZ)",
    "254 - (KEN)",
    "996 - (KGZ)",
    "855 - (KHM)",
    "686 - (KIR)",
    "1 - (KNA)",
    "82 - (KOR)",
    "965 - (KWT)",
    "856 - (LAO)",
    "961 - (LBN)",
    "231 - (LBR)",
    "218 - (LBY)",
    "1 - (LCA)",
    "423 - (LIE)",
    "94 - (LKA)",
    "266 - (LSO)",
    "370 - (LTU)",
    "352 - (LUX)",
    "371 - (LVA)",
    "853 - (MAC)",
    "590 - (MAF)",
    "212 - (MAR)",
    "377 - (MCO)",
    "373 - (MDA)",
    "261 - (MDG)",
    "960 - (MDV)",
    "52 - (MEX)",
    "692 - (MHL)",
    "389 - (MKD)",
    "223 - (MLI)",
    "356 - (MLT)",
    "95 - (MMR)",
    "382 - (MNE)",
    "976 - (MNG)",
    "1 - (MNP)",
    "258 - (MOZ)",
    "222 - (MRT)",
    "1 - (MSR)",
    "596 - (MTQ)",
    "230 - (MUS)",
    "265 - (MWI)",
    "60 - (MYS)",
    "262 - (MYT)",
    "264 - (NAM)",
    "687 - (NCL)",
    "227 - (NER)",
    "672 - (NFK)",
    "234 - (NGA)",
    "505 - (NIC)",
    "683 - (NIU)",
    "31 - (NLD)",
    "47 - (NOR)",
    "977 - (NPL)",
    "674 - (NRU)",
    "64 - (NZL)",
    "968 - (OMN)",
    "92 - (PAK)",
    "507 - (PAN)",
    "64 - (PCN)",
    "51 - (PER)",
    "63 - (PHL)",
    "680 - (PLW)",
    "675 - (PNG)",
    "48 - (POL)",
    "1 - (PRI)",
    "850 - (PRK)",
    "351 - (PRT)",
    "595 - (PRY)",
    "970 - (PSE)",
    "689 - (PYF)",
    "974 - (QAT)",
    "262 - (REU)",
    "40 - (ROU)",
    "7 - (RUS)",
    "250 - (RWA)",
    "966 - (SAU)",
    "249 - (SDN)",
    "221 - (SEN)",
    "65 - (SGP)",
    "500 - (SGS)",
    "290 - (SHN)",
    "47 - (SJM)",
    "677 - (SLB)",
    "232 - (SLE)",
    "503 - (SLV)",
    "378 - (SMR)",
    "252 - (SOM)",
    "508 - (SPM)",
    "381 - (SRB)",
    "211 - (SSD)",
    "239 - (STP)",
    "597 - (SUR)",
    "421 - (SVK)",
    "386 - (SVN)",
    "46 - (SWE)",
    "268 - (SWZ)",
    "1 - (SXM)",
    "248 - (SYC)",
    "963 - (SYR)",
    "1 - (TCA)",
    "235 - (TCD)",
    "228 - (TGO)",
    "66 - (THA)",
    "992 - (TJK)",
    "690 - (TKL)",
    "993 - (TKM)",
    "670 - (TLS)",
    "676 - (TON)",
    "1 - (TTO)",
    "216 - (TUN)",
    "90 - (TUR)",
    "688 - (TUV)",
    "886 - (TWN)",
    "255 - (TZA)",
    "256 - (UGA)",
    "380 - (UKR)",
    "246 - (UMI)",
    "383 - (UNK)",
    "598 - (URY)",
    "1 - (USA)",
    "998 - (UZB)",
    "379 - (VAT)",
    "1 - (VCT)",
    "58 - (VEN)",
    "1 - (VGB)",
    "1 340 - (VIR)",
    "84 - (VNM)",
    "678 - (VUT)",
    "681 - (WLF)",
    "685 - (WSM)",
    "967 - (YEM)",
    "27 - (ZAF)",
    "260 - (ZMB)",
    "263 - (ZWE)",
];
