import { Text } from "@chakra-ui/react";
import { FieldErrors } from "react-hook-form";
import { type FormData } from "./CreateModal";
import React from 'react'

type ErrorProps ={
errors:FieldErrors<FormData>;

}
export default function Errors({errors}:ErrorProps) {
    const firstError = 
    errors.siteName?.message ||
    errors.city?.message ||
    errors.country?.message ||
    errors.displayCount?.message ||
    errors.status?.message ||
    errors.asignee?.email?.message;

    return(
        <div>
            {firstError && <Text style={{ color: 'red' }}>{firstError}</Text>}
        </div>
    )
    
}