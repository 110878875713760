/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useCallback, useContext, useState } from "react";
import {  Box, Button, Flex, Heading, HStack, Img, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";

import MobileSideNav from "./MobileSIdeNav.tsx";
import { ArrowBackIcon } from "@chakra-ui/icons";
import GlobalContext from "../CommonMethods/GlobalContext.tsx";

import Debounce from "../CommonMethods/Debounce.ts";
import { useVarDispatch } from "../redux/hooks.ts";
import { handleLoggedInState } from "../redux/persistedStates.ts";
import { useNavigate } from "react-router-dom";

export default function Navbar({isOpen,onToggle}:{isOpen:boolean,onToggle:()=>void}){
    const {searchTerm,setSearchTerm,setSkip,setScreenLoading,setEmail} = useContext(GlobalContext)
    const dispatch = useVarDispatch()
    const navigate = useNavigate()

 
    const handleSearch = (value:string) => {
        if(value!==''){
            setSkip(false)
            setScreenLoading(false)
        }
   
    };

    const optimizedFn = useCallback(
        Debounce({func:(value:string) => handleSearch(value)}),
        []
    );
    const [isSearchIconClicked,setIsSearchIconClicked] = useState<boolean>(false)
    return (
        <Box>
            <Flex as="nav" p="10px"  mb='40px' alignItems='center' justifyContent={'space-between'}>
                <MobileSideNav onToggle={onToggle} isOpen={isOpen}/>
                <Box display={isSearchIconClicked?'none':'block'}>
                    <Img  src='/images/HTV_Logo.png'/>
                </Box>
                <Box width={isSearchIconClicked?'100%':'40%'} >  
                    <Button variant={'ghost'} display={isSearchIconClicked?'none':{base:'block',md:'none'}} onClick={()=>{setIsSearchIconClicked(prev=>!prev)}}> 
                        <Img mx="auto" src={'/images/Icon.png'} width={5} height={5}/>
                    </Button>
                    <HStack>
                        <ArrowBackIcon display={isSearchIconClicked?'block':'none'} onClick={()=>{setIsSearchIconClicked(false)}}/>
                        <InputGroup display={isSearchIconClicked?'block':{base:'none',md:'block'}}>
                       
                            <InputLeftElement
                                pointerEvents="none" // Prevents the icon from blocking input clicks
                            >
                                <Img src={'/images/Icon.png'} width={5} height={5}/>
                            </InputLeftElement>
                            <Input   _focus={{
                                borderColor: "#CAD2DB",  // Same border color when focused
                                boxShadow: "none"           // Remove the default focus outline shadow
                            }} borderRightWidth="1px" borderTopColor="#CAD2DB" borderRadius={'50px'} fontSize="15px" placeholder="Search" value={searchTerm} onChange={(e)=>{optimizedFn(e.target.value);setSearchTerm(e.target.value);setSkip(true);setScreenLoading(true)}}/>
                        </InputGroup>
                    </HStack>
                    
                </Box>
           
                <Flex alignItems={'center'} display={isSearchIconClicked?'none':'block'}>
                    <HStack spacing="20px" mr='2'>
                        <Img src='/images/Notification.png'/>
                        <Img src='/images/Help.png'/>
                        <Heading as={'h2'} fontSize={'16px'} fontWeight={'700'}>Mike williams</Heading>
                        <Img src={'/images/ChevronDown.png'}/>
                        <Button bg="none" 
                            onClick={()=>{dispatch(handleLoggedInState(false));navigate('/login');setEmail('')}}
                        ><Img src={'/images/logouticon.png'} w="5" height="5" alt="logout"/></Button>
                    </HStack>
                    
                </Flex>
            </Flex>
        </Box>
    )
} 


//line height
//paddings in px
//placeholder should be nuegre font