/* eslint-disable max-len */
import { Box, Text } from "@chakra-ui/react";
import React from "react";
type SearchEmptyScreenProps = {
    text:string
}
export default function SearchEmptyScreen({text}:SearchEmptyScreenProps){
    return(
        <Box bg={'white'} height={'calc(100vh - 262px)'} marginTop={'40px'} marginLeft={'40px'} marginRight={'40px'}     display="flex"
            justifyContent="center"
            alignItems="center">
            <Text as="h1"  fontSize={'40'}>{text}</Text>
        </Box>
    )

}