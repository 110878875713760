import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React, { useState } from "react";

type inputFieldProps = {
    inputValue:string;
    label:string;
    setError?:React.Dispatch<React.SetStateAction<string>>;
    setIsPasswordFocussed?: React.Dispatch<React.SetStateAction<boolean>>;
    setInputValue : React.Dispatch<React.SetStateAction<string>>
}

export default function InputField({inputValue,setError,setIsPasswordFocussed=()=>{},setInputValue,label}:inputFieldProps){
    const [isFocussed,setIsFocussed] = useState(false)
    return(
        <FormControl position="relative" mt={4}>
            <Input
                placeholder=" " // Placeholder with a space to trigger the floating effect
                value={inputValue}
                border={'1px'}
                onChange={(e) => {
                    
                    setInputValue(e.target.value)
                    
                }}
                variant="outline"
                onFocus={()=>{setIsFocussed(true);setIsPasswordFocussed(true); setError?.('')}}
                onBlur={()=>{setIsFocussed(false);setIsPasswordFocussed(false)}}
                _focus={{
                    borderColor: "#000000", // Keep black border color
                    borderWidth: "1px", // Keep the initial border width as 1px (black)
                    boxShadow: "0 0 0 3px rgba(0, 114, 219, 0.5)", // Blue border with space around the black border
                }}
                _hover={{
                    borderColor: "#000000"
                }}
                
                height="40px"
                width={{sm:"412px"}} 
            />
            <FormLabel
                position="absolute"
                bg="white"
                zIndex={1}
                top="25%"
                left="1rem"
                transform={isFocussed||inputValue?.length>0 ? "translateY(-90%) scale(0.85)" : "translateY(0)"}
                transformOrigin="left top"
                color={isFocussed||inputValue?.length>0?"#15191E":'#566676'}
                fontSize="sm"
                transition="0.2s ease all"
                pointerEvents="none"
            >
                {label}
            </FormLabel>
        </FormControl>
    )
}