/* eslint-disable max-len */
import {  Button, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import React from "react";
import { type salutations } from "../pages/Authentication/PersonalDetails";

type DropdownType = {
    salutations:salutations;
    setSalutations:React.Dispatch<React.SetStateAction<salutations>>;
}

export default function Dropdown({salutations,setSalutations}:DropdownType){
    const handleSelect = (item: salutations) => {
        setSalutations(item); // Update selected item
    };
    return(
        <Menu>
            <MenuButton 
                bg="none" 
                pl='0'
                border="1px" 
                _hover={{
                    borderColor: "#000000",
                    bg:'none'
                }}
                _expanded={{
                    bg: 'none', // Removes the background when the button is expanded
                }}
                _focus={{
                    bg:'none',
                    borderColor: "#000000", // Keep black border color
                    borderWidth: "1px", // Keep the initial border width as 1px (black)
                    boxShadow: "0 0 0 3px rgba(0, 114, 219, 0.5)", // Blue border with space around the black border
                }}
                width={{sm:"412px"}} 
                height="40px" 
                as={Button} 
                rightIcon={<img src="/images/dropdownicon.png" alt="dropdown" />}>
                <Text 
                    fontWeight={'350'}
                    fontSize={'sm'}
                    color={salutations===''?'#566676':'black'}
                    textAlign={'left'}
                    ml="0.75rem"
                    
                >{salutations===''?"Title":salutations}</Text>
            </MenuButton>
            <MenuList w={'412px'}>
                <MenuItem onClick={() => handleSelect("Mr.")}>Mr.</MenuItem>
                <MenuItem onClick={() => handleSelect("Ms.")}>Ms.</MenuItem>
                <MenuItem onClick={() => handleSelect("Mrs.")}>Mrs.</MenuItem>
            
            </MenuList>
        </Menu>
    )
}