import {  Box, Button, Flex, FormControl, Input, Menu, MenuItem, MenuList, Text } from "@chakra-ui/react"
import React, { useState } from "react"
import { phoneCodeArray } from "../CommonMethods/phoneNumberCountryCodes"


type inputFieldProps = {
    inputValue:string;
    label:string;
    setInputValue : React.Dispatch<React.SetStateAction<string>>
}


export default function PhoneInputField({inputValue,setInputValue,label}:inputFieldProps){
    const [isOpen,setIsOpen] = useState(false)
    const [countrycode,setCountryCode] = useState('')
    return(
        <FormControl position="relative" mt={4}>     
            <Flex  
                mt="2"
                borderRightWidth="1px" 
                width="130px" 
                pl="1rem"
                borderColor="#6B8094" 
                position="absolute"
                zIndex="1" // Ensure the Flex is on top of the Input
                cursor="pointer"
                onClick={(e) => {e.stopPropagation();setIsOpen(prev=>!prev)}} // Prevent Input focus on Flex click
            >
                <Text color="black" mr={6}>
                    {countrycode!==''?'+'+countrycode?.split('-')?.[0]:'+000'}
                </Text>
                <Button  
                   
                    bg="none" 
                    _hover={{ bg: "transparent"}}
                    height="auto"
                    cursor="pointer" 
                    rightIcon={<img src="/images/dropdownicon.png" alt="dropdown" />}
                />
            </Flex>
            <Menu isOpen={isOpen}>
                <MenuList 
                    position="absolute" 
                    top="40px" 
                    zIndex="50" 
                    left="0" 
                    p="2" 
                    borderRadius="lg" 
                    borderColor="#C5C9DA" 
                    bg="white"
                    minWidth="unset" 
                    overflow="hidden"
                >
                    <Box  width="120px" maxH="150px" overflowY="auto" pr="1" sx={{
                        '&::-webkit-scrollbar': {
                            width: '9px',
                            borderRadius: '8px',
                            backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        },
                   
                        '&::-webkit-scrollbar-thumb': {
                    
                            backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        },
                    }} >
                        {phoneCodeArray?.map((item, index) => (
                            <MenuItem
                                key={index}
                                fontSize={"sm"}
                                fontWeight="normal"
                                color={"#000"}
                                _hover={{ color: "#008586" }}
                                onClick={() => {setCountryCode(item);setIsOpen(false)}}
                            >
                                {item}
                            </MenuItem>
                        ))}
                    </Box>
                </MenuList>
            </Menu>
            <Input
                placeholder={label}
                _placeholder={{fontFamily: "'Open Sans', sans-serif",fontSize:'15px',color:'#566676'}}
                value={inputValue}
                variant="outline"
                _focusVisible={{ borderColor: "blue.500" }}
                height="40px"
                border="1px"
                _hover={{
                    borderColor: "#000000"
                }}
                onChange={(e) => {
                    if (/^\d*$/g.test(e.target.value)) {
                        setInputValue(e.target.value)
                    }
                }}
                _focus={{
                    borderColor: "#000000", // Keep black border color
                    borderWidth: "1px", // Keep the initial border width as 1px (black)
                    boxShadow: "0 0 0 3px rgba(0, 114, 219, 0.5)", // Blue border with space around the black border
                }}
                paddingLeft="135px" // Extra padding to avoid overlapping the Flex content
                width={{ sm: "412px" }}
            />
        </FormControl>

    )
}