import { Box, Img, List, ListItem, Text } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import React from "react"


export const LinkItems = [
    { name: 'Dashboard', icon: '/images/Dashboard.png' },
    { name: 'Sites', icon: '/images/Sites.png' },
    { name: 'Tasks', icon: '/images/Tasks.png' },
    { name: 'Displays', icon: '/images/Displays.png' },
    { name: 'Config', icon: '/images/Config.png' },
    {name:'License', icon:'/images/License.png'},
    { name: 'Create', icon:'/images/Create.png'  },
]
export default function SideBar(){
    return(
        <List spacing={10} >
            <ListItem> 
                {LinkItems.map(item=>{return(<NavLink to={'/'} key={item.name}>
                    <Box p={3} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                        <Img src={item.icon} boxSize={'25px'}/>
                        <Text fontSize={'10px'} fontWeight={400} >{item.name}</ Text>
                    </Box>
                </NavLink>)})}
            </ListItem>
        </List>
    )
   
}