/* eslint-disable max-len */
import React from "react"
import { Grid, GridItem, useDisclosure } from "@chakra-ui/react"
import SideBar from "./Sidebar.tsx"
import Navbar from "./Navbar.tsx"
import SiteListing from "../pages/Sites/SiteManagement/listing.tsx"

export default function RootLayout(){
    const { isOpen ,onToggle} = useDisclosure()
    return (
        <>
            <Grid 
                templateAreas={{base:`'header header header'
                               'main main main'`,
                md:`'header header header'
                               'navbar main main'`
                }}
                
                templateRows={'72px 1fr'}
                templateColumns='100px 1fr'
                height='100vh'
                overflowX={'hidden'}
            >
                <GridItem top={'0'} left={'0'} width={'full'} gridArea='header' px={7} borderBottomWidth="1px" borderTopWidth="1px" borderTopColor="#CAD2DB" borderBottomColor="#CAD2DB"   bg='white'><Navbar isOpen={isOpen} onToggle={onToggle}/></GridItem>
                <GridItem  gridArea='navbar' display={{base:"none",md:'block'}} borderRightWidth="1px" borderBottomColor="#CAD2DB" p={'24px'} bg='white.200'><SideBar/></GridItem>  
                <GridItem  gridArea='main' height='full' width='full' overflowX={'hidden'}  bg={"gray.100"} ><SiteListing/></GridItem> 
            </Grid>
           
            
        </>
    )
}