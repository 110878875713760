/* eslint-disable max-len */
import { Box, Img, Text, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import WelcomePage from "./WelcomePage";
import GlobalContext from "../../CommonMethods/GlobalContext";
import ActivateAccount from "./Otp";
import ActivationSuccessScreen from "./AccountActivationPage";
import LoggingInScreen from "./LoggingInScreen";

export  type currentScreen  = 'welcomeScreen' | 'SignIn' | 'PersonalDetails' | 'OTP' |'ActivationSuccess'|'LoggingIn'|'LoggedIn';
export default function Registration (){
   

    const {currentScreen,email} = useContext(GlobalContext)
    
    // const  [isAnimationCompleted,setIsAnimationCompleted] = useState(false)
    return(
        <>
            {currentScreen==="LoggingIn"?<LoggingInScreen/> :<Box bg="#0072DB" height='100vh' py="8" >
                <Box borderRadius="10px" bg="white" top={"125px"} mx="auto" left="660px" height={{lg:"800px"}} width={{sm:"550px",md:"600px"}} px="94px" pt={"80px"} pb='30px'>
                    <VStack gap={'50px'}>  
                        <Img src={'/images/TPV_Logo.png'}  alt="TPVision logo"/>
                        <VStack gap={'8px'}>
                            <Text  color="#15191E"  fontSize="24px" fontWeight="700" as="h3">
                                {currentScreen==='OTP'?'Activate your account':
                                    currentScreen==='welcomeScreen'?
                                        'Welcome to Wave Hospitality':currentScreen==='ActivationSuccess'?'Account activated':'Create an account'}</Text>
                            {currentScreen==='OTP'?
                                <Text color="#15191E"  as="h3" fontSize="16px" fontWeight="350">{`Enter the code sent on ${email}`}</Text>:
                                currentScreen==='welcomeScreen'?
                                    <Text as="h3" color="#15191E"  fontSize="16px" fontWeight="350">Please select the product</Text>:
                                    currentScreen==='ActivationSuccess'?<Text as="h3" color="#15191E"  fontSize="16px" fontWeight="350">Congratulations !  Your account has been activated.</Text>:<Text  as="h3" color="#15191E"  fontSize="16px" fontWeight="350">Already have an account? <Link to="/login" style={{textDecoration:"underline",color:'#0072DB'}}>Log in</Link></Text>}
                        </VStack>
                        {currentScreen==="welcomeScreen"&&<WelcomePage />}
                        {currentScreen==="OTP"&&<ActivateAccount/> }
                        {currentScreen==="ActivationSuccess"&&<ActivationSuccessScreen/> }
                    
                    </VStack>
                    <Img mt={"40px"} src={'/images/Copyrights.png'} alt="copyright"/>
                </Box>
            </Box>}
        </>
    )
}