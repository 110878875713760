import {  Button,  VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import GlobalContext from "../../CommonMethods/GlobalContext";

export default function ActivationSuccessScreen(){

    const {setCurrentScreen} = useContext(GlobalContext)
    return(
        <VStack>
            <Button
                mt={'28px'}
                type={"button"}
                borderRadius="20px"
                width={{sm:"416px"}}
                height="40px"
                bg="#0072DB"
                _hover={{bg:'#0072DB'}}
                color="white"
                data-testid="proceed_to_login"
                onClick={()=>{setCurrentScreen('LoggingIn')}}
                mb='150px'
                        
            >
                {"Proceed to log in"}
            </Button>

            

        </VStack>
    )
}