import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AppApolloProvider from "./graphql/ApolloProvider";
import { persistor, store } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const rootElement = document.getElementById("root")

if(rootElement){
    ReactDOM.createRoot(rootElement).render(
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>   
                    <AppApolloProvider>
                        <React.StrictMode>
                            <App />
                        </React.StrictMode>
                    </AppApolloProvider>           
                </BrowserRouter>
            </PersistGate>
        </Provider>,
    );
}else{
    console.error("Root element not found");
}



