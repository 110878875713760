import {persistReducer,persistStore} from 'redux-persist'
import storage from 'localforage'
import persistedSlice from './persistedStates'
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
    key: 'root', // key for the storage
    storage, // storage engine to use
};
const persistedReducer = persistReducer(persistConfig, persistedSlice);

export const store = configureStore({
    reducer:{
        persistedState:persistedReducer
    }
})

export const persistor  = persistStore(store)


export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>