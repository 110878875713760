/* eslint-disable max-len */
import { Button, Flex, FormLabel, Image, Input, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from "@chakra-ui/react"
import  { useState } from "react"
import {type FormData } from "./CreateModal.tsx"
import { UseFormRegister } from "react-hook-form"
import React from 'react'

type InviteUserProps = {
    register:UseFormRegister<FormData>;
    handleSelectStatus:(status:string)=>void;
    selectedStatus:string|null;
    setShowError : React.Dispatch<React.SetStateAction<boolean>>
}
export default function InviteUsers({register,handleSelectStatus,selectedStatus,setShowError}:InviteUserProps){
    const [isOpen1,setIsOpen1]= useState(false)
    const [isOpen2,setIsOpen2]= useState(false)
    const [isOpen3,setIsOpen3]= useState(false)
    //const [isOpen4,setIsOpen4]= useState(false)
    return(
        <>
            <FormLabel mb="4" as="h2" color="#0072DB" htmlFor='username' fontWeight={'700'} fontSize={'20px'}>Invite users</FormLabel>
            <Text fontWeight={'350'} fontSize={'16px'} color={'#566676'}>You can always skip this step and add users later</Text>
            <Stack spacing={6} mt={'12'} mb={'44'}>
                <Button justifyContent={'space-between'} onClick={()=>setIsOpen1(prev=>!prev)} >
                    Owner
                    <Image src="/images/ChevronDown.png"/>
                </Button>
                <Flex  display={isOpen1?'flex':'none'} px='2' gap="4" justifyContent={'space-between'}>
                    <Input borderColor={'black'} placeholder="Enter name" {...register('owner.name')}
                    />
                    <Input borderColor={'black'} placeholder="Enter email"{...register('owner.email',
                        {
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                            }, })}/>
                    {/* <Button colorScheme={'blue'}>Invite</Button> */}
                </Flex>

                <Button justifyContent={'space-between'} onClick={()=>setIsOpen2(prev=>!prev)}>
                    Chain Manager
                    <Image src="/images/ChevronDown.png"/>
                </Button>
                <Flex display={isOpen2?'flex':'none'} px='2' gap="4" justifyContent={'space-between'}>
                    <Input borderColor={'black'} placeholder="Enter name" {...register('chainManager.name')} onFocus={()=>setShowError(false)}/>
                    <Input borderColor={'black'} placeholder="Enter email" {...register('chainManager.email',
                        {
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                            }, })} onFocus={()=>setShowError(false)}/>
                </Flex>

                <Button justifyContent={'space-between'} onClick={()=>setIsOpen3(prev=>!prev)}>
                    Asignee
                    <Image src="/images/ChevronDown.png"/>
                </Button>
                <Flex display={isOpen3?'flex':'none'} px='2' gap="4" justifyContent={'space-between'}>
                    <Input borderColor={'black'} placeholder="Enter name" {...register('asignee.name')} onFocus={()=>setShowError(false)}/>
                    <Input borderColor={'black'} placeholder="Enter email" {...register('asignee.email',
                        {required:'Email is a required field',
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                            }, })} onFocus={()=>setShowError(false)}/>
                </Flex>

                <Menu>
                    <MenuButton as={Button} rightIcon={<img src='/images/ChevronDown.png' alt="arrow-down"/>}  >
                        <Text textAlign={'left'}>{selectedStatus?`${selectedStatus}`:'Overview Status'}</Text>
                    </MenuButton>
                    <MenuList w={'400px'} {...register('status')}>
                        <MenuItem px={'10'} onClick={()=>handleSelectStatus('Stable')}>
                            <Text as='h3' w="300px" textAlign={'center'} color={'#FFFFFF'} px={'18px'} borderRadius={5} bg={"#41B546"} padding={3} fontWeight='400' fontSize='14px' py={2}>Stable</Text>
                        </MenuItem>
                        <MenuItem px={'10'} onClick={()=>handleSelectStatus('Attention')}>
                            <Text as='h3' w="300px" textAlign={'center'} color={'#FFFFFF'} px={'18px'} borderRadius={5} bg={"#F2A40E"} padding={3} fontWeight='400' fontSize='14px' py={2}>Attention</Text>
                        </MenuItem>
                        {/* '#F2A40E' */}
                    </MenuList>
                </Menu>
                
            </Stack>
        </>
    )
}