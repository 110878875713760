/* eslint-disable max-len */
/* eslint-disable max-len */
import { Input } from "@chakra-ui/react";
import React, { useRef, useEffect, Fragment } from "react";

type otpInputFieldType = {
    numInputs:number;
    inputs:string[];
    setInputs:React.Dispatch<React.SetStateAction<string[]>>;
    error:boolean;
    seterror:React.Dispatch<React.SetStateAction<boolean>>
}
const OtpInputField = ({ numInputs, inputs, setInputs, seterror }:otpInputFieldType) => {
    const refs = useRef<Array<HTMLInputElement | null>>([]);
    const firstInputRef = useRef<HTMLInputElement | null>(null);

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>, index:number) => {
        const value = e.target.value;
        const val = value?.split("").at(-1);
        setInputs((prevInputs) => {
            const newInputs = [...prevInputs];
            newInputs[index] = val as string;
            return newInputs;
        });

        if (value.length === 1 && index < numInputs - 1) {
            const nextInput = refs.current[index + 1];
            if (nextInput) {
                nextInput.focus();
            }
        }
    };


    useEffect(() => {
        // Focus on the first input field when the component mounts
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    // useEffect(() => {
    //     // Focus on the first input field when the component mounts
    //     refs.current[0].focus()
    // }, [error]);

    const handlePaste = (e:React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text");
        const pastedChars = pastedData.split("").slice(0, numInputs);
        setInputs(pastedChars);

        if (pastedChars.length > 0) {
            refs.current?.[pastedChars.length - 1]?.focus();
        }
    };
    const handleKeyDown = (e:React.KeyboardEvent<HTMLInputElement>, index:number) => {
        if (e.keyCode === 8 && index > 0) {
            if (inputs[index] === "") {
                refs?.current[index - 1]?.focus();
                setInputs((prevInputs) => {
                    const newInputs = [...prevInputs];
                    newInputs[index - 1] = "";
                    return newInputs;
                });
            } else {
                refs?.current[index]?.focus();
                setInputs((prevInputs) => {
                    const newInputs = [...prevInputs];
                    newInputs[index] = "";
                    return newInputs;
                });
            }
        }
    };
    return (
        <>
            {inputs?.map((value, index) => (
                <Fragment key={index}>
                    <Input
                        key={index}
                        type="text"
                        id={index.toString()}
                        autoFocus={index === 0}
                        pattern="[0-9]*"
                        value={value}
                        _focus={{
                            borderColor: "#000000", // Keep black border color
                            borderWidth: "1px", // Keep the initial border width as 1px (black)
                            boxShadow: "0 0 0 3px rgba(0, 114, 219, 0.5)", // Blue border with space around the black border
                        }}
                        _hover={{borderColor:'#6B8094'}}
                        autoComplete="off"
                        onFocus={() => seterror(false)}
                        width={'43px'} borderColor={'#6B8094'} borderWidth={'1px'} mr={'10px'} textAlign={'center'}
                        onChange={(e) => {
                            if (!/[^0-9]/g.test(e.target.value)) {
                                handleChange(e, index);
                            }
                        }}
                        onPaste={handlePaste}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => {
                            refs.current[index] = el;
                        }}
                    />
                </Fragment>
            ))}
        </>
    );
};

export default OtpInputField;
