/* eslint-disable max-len */
import { Box, Button, Img, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import PasswordInputField from "../../components/PasswordInputField";
import InputField from "../../components/inputField";
import { Link, useNavigate } from "react-router-dom";
import { useVarDispatch } from "../../redux/hooks";
import { handleLoggedInState } from "../../redux/persistedStates";

export default function Login() {
    const [email, setEmail] = useState("");
    const dispatch = useVarDispatch()
    const navigate = useNavigate()
    const [password, setPassword] = useState("");
    const [error, setError] = useState(''); // State for error message

    // Handle login button click
    const handleLogin = () => {
        // Check if email or password is empty
        if (!email || !password) {
            setError("Please fill in both email and password.");
        } else if (!isValidEmail(email)) {
            setError("Please enter a valid email address.");
        }else {
            setError("");
            // Proceed with login logic
            dispatch(handleLoggedInState(true))
            navigate('/')
        }
    };
    const isValidEmail = (email:string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // checks for an email pattern
    };

    return (
        <Box bg="#0072DB" height="100vh" py="8">
            <Box
                borderRadius="10px"
                bg="white"
                top="125px"
                mx="auto"
                left="660px"
                height={{ lg: "800px" }}
                width={{ sm: "550px", md: "600px" }}
                px="94px"
                pt="90px"
                pb="30px"
            >
                <VStack gap="50px">
                    <Img src="/images/TPV_Logo.png" alt="TPVision logo" />
                    <VStack gap="8px">
                        <Text as="h3" color="#15191E" fontSize="24px" fontWeight="700">
                            Wave Hospitality
                        </Text>
                        <Text color="#15191E" fontSize="16px" fontWeight="350">
                            New user?{" "}
                            <Link to="/signup" style={{ textDecoration: "underline", color: "#0072DB" }}>
                                Create account
                            </Link>
                        </Text>
                    </VStack>

                    <Box mt="10px">
                        <InputField setError={setError} label="Username/email" inputValue={email} setInputValue={setEmail} />
                        <PasswordInputField setError={setError} label="Password" inputValue={password} setInputValue={setPassword} />

                        {/* Error message */}
                        {error && (
                            <Text color="red.500" textAlign="center" mt="4" fontSize="14px">
                                {error}
                            </Text>
                        )}

                        <Button
                            mt="24px"
                            type="button"
                            borderRadius="20px"
                            width={{ sm: "416px" }}
                            height="40px"
                            bg="#0072DB"
                            _hover={{ bg: "#0072DB" }}
                            color="white"
                            onClick={handleLogin}
                        >
                            Log in
                        </Button>

                        <Text textAlign="center" mt="4" mb="50px">
                            <Link to="" style={{ textDecoration: "underline", color: "#0072DB" }}>
                                Forgot password?
                            </Link>
                        </Text>
                    </Box>
                </VStack>
                <Img mt={password?.length > 0 ? "4px" : "40px"} src="/images/Copyrights.png" alt="copyright" />
            </Box>
        </Box>
    );
}
